import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(private acRoute: ActivatedRoute, private router: Router) {
    if(location.href.includes('localhost')) {
      this.menus.push({
        title: 'Kod Listesi',
        icon: 'logo-closed-captioning',
        active: false,
        link: '/pages/codes-lists'
      });
    }
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        console.log(data);
        this.menus.forEach(elm => elm.active = false);
        if (data.url == '/pages') {
          this.menus[0].active = true;
        } else {
          let foundIndex = this.menus.findIndex((elm) => elm.link == data.url);
          if (foundIndex >= 0) {
            this.menus[foundIndex].active = true;
          }
        }
      }
    });
  }

  menus = [
    {
      title: 'Dashboard',
      icon: 'home',
      active: false,
      link: '/',
      // tag: '/pages',
    },
    {
      title: 'Davetli Listesi',
      icon: 'people',
      active: false,
      link: '/pages/guest-list',
      // tag: '/pages/guest-list',
    },
    {
      title: 'Sponsor Listesi',
      icon: 'cash-outline',
      active: false,
      link: '/pages/sponsor-list'
    }
  ];

  ngOnInit() {}
}
