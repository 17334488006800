// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   apiKey: 'OSaGeVpFIYht35a1GeWBjuiNRSO', //FSUMMİT
  //apiKey: 'WTJGeVpDMWpkV3hwYm1GeWVTMHlNR', //CULINARY
  //apiKey : 'ugWXHQUnmBvJs4klqDqzfOyyVVwKlhrn', //TURYID
apiUrl: 'https://guest-card-service.kerzz.com:6700',
  orwiServiceUrl: 'https://service-test.orwi.app/api/helper/getCountries',
  // apiUrl: 'https://127.0.0.1:6700',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
