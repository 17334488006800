import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './components/text-input/text-input.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { IonicModule } from '@ionic/angular';
import { DxButtonModule, DxDataGridModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

const MODULES = [DxDataGridModule, DxButtonModule,DxLoadIndicatorModule];

const COMPONENTS = [TextInputComponent, SidebarComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    RouterModule,
    ...MODULES,
  ],
  exports: [...COMPONENTS, ...MODULES, FormsModule, ReactiveFormsModule,IonicModule],
})
export class SharedModule {}
